import axios from "@/axios";
export function getVideoData() {
  const url = "live_video/videoCloumnList.json";
  return axios
    .post(url, {
      clientType: 2
    })
    .then(res => {
      return Promise.resolve(res);
    });
}
export function getVideoCategory(params) {
  const url = "live_video/videoCriteriaQueryList.json";
  return axios.post(url, params).then(res => {
    return Promise.resolve(res);
  });
}
export function getVideoList(params) {
  const url = "live_video/seriesVideoList.json";
  return axios.post(url, params).then(res => {
    return Promise.resolve(res);
  });
}
export function getNormalList(params) {
  const url = "live_video/seriesVideoList.json";
  return axios.post(url, params).then(res => {
    return Promise.resolve(res);
  });
}
export function getVideoDetails(params) {
  const url = "live_video/view.json";
  return axios.post(url, params).then(res => {
    return Promise.resolve(res);
  });
}
export function buyVideo(params) {
  const url = "live_video/payVideo.json";
  return axios.post(url, params).then(res => {
    return Promise.resolve(res);
  });
}
export function getHotWordList(params) {
  const url = "hot_word/list.json";
  return axios.post(url, params).then(res => {
    return Promise.resolve(res);
  });
}
export function getInputKeywordList(params) {
  const url = "live_video/inputKeywordQuery.json";
  return axios.post(url, params).then(res => {
    return Promise.resolve(res);
  });
}
export function searchResult(params) {
  const url = "live_video/keywordQueryList.json";
  return axios.post(url, params).then(res => {
    return Promise.resolve(res);
  });
}
