<template>
  <div class="search-container">
    <h :isback="true">
      <div class="search-box">
        <img src="./img/search.png" width="16" class="icon" />
        <input
          ref="searchInput"
          v-model="keyword"
          type="search"
          placeholder="搜索视频"
          @input="keywordSearch"
          @keyup.13="presssearch"
        />
      </div>
    </h>
    <div v-show="mode == 0" class="history">
      <div v-if="searchHistory.length" class="title">
        <span class="name">最近搜索</span>
        <span class="btn" @click="clearSearchHistory">清除历史</span>
      </div>
      <div v-if="searchHistory.length" class="list">
        <span
          v-for="(item, inx) in searchHistory"
          :key="inx"
          class="item"
          v-text="item"
          @click="historyKeywordSearch(item)"
        />
      </div>
      <div class="title">
        <span class="name">热门搜索</span>
      </div>
      <div class="list">
        <span
          v-for="item in hotWrodList"
          :key="item.keyWord"
          class="item"
          v-text="item.keyWord"
          @click="historyKeywordSearch(item.keyWord)"
        />
      </div>
    </div>
    <div v-show="mode == 1" class="keyword-list">
      <ul v-show="keywordSearchList.length">
        <li
          v-for="(item, inx) in keywordSearchList"
          :key="inx"
          v-text="item.name"
          class="border-bottom-1px"
          @click="inputSearch(item)"
        />
      </ul>
      <div v-show="keywordSearchList.length <= 0" class="not-data">
        搜索不到相关信息，请换个关键词试试
      </div>
    </div>
    <div v-show="mode == 2" class="result">
      <div class="top border-bottom-1px">
        <div class="title ellips">
          搜索“<span v-text="keyword" />”,找到相关结果:{{ list.length }}个
        </div>
        <div class="types">
          <span
            v-for="(item, inx) in types"
            :key="item"
            class="item"
            :class="{ active: inx == roleStatus }"
            v-text="item"
            @click="changeRole(inx)"
          />
        </div>
      </div>
      <div v-if="list.length > 0" class="list">
        <cube-scroll
          ref="scroll"
          :data="list"
          :options="options"
          @pulling-down="onPullingDown"
          @pulling-up="onPullingUp"
        >
          <div
            v-for="(series, seriesInx) in list"
            :key="seriesInx"
            class="series border-bottom-1px"
          >
            <div class="introduction">
              <div class="l">
                <img
                  v-lazy="
                    series.videoType == 0 || series.videoType == 3
                      ? resetUrl(series.horizontalImage)
                      : resetUrl(series.verticalImage)
                  "
                  class="cover"
                />
                <span v-if="series.isAllVip == -1" class="vip">VIP</span>
                <span
                  v-if="series.videoType == 3"
                  class="vip"
                  v-text="series.isPay ? '已解锁' : '金币'"
                />
              </div>
              <div class="r">
                <div class="name ellips">
                  {{ series.titleBefore }}
                  <span class="word">{{ keyword }}</span>
                  {{ series.titleAfter }}
                </div>
                <div class="date">
                  <span class="time">发布时间：{{ series.releaseTime }}</span>
                  <span class="total">视频数：{{ list.length }}</span>
                </div>
                <div class="desc" v-text="series.description" />
                <cube-button class="btn" @click="play(series, 0)">
                  立即播放
                </cube-button>
              </div>
            </div>
            <ul v-show="series.videoType != 0 && series.videoType != 3">
              <li
                v-for="(video, videoInx) in series.categorySubVideoList"
                :key="videoInx"
                v-show="videoInx < 10"
                @click="play(video, videoInx, series)"
              >
                <span v-text="videoInx < 9 ? video.number : '···'" />
                <span v-if="video.roleStatus == 2 && videoInx < 9" class="vip">
                  VIP
                </span>
              </li>
            </ul>
          </div>
        </cube-scroll>
      </div>
      <div v-show="listLess" class="not-data">
        搜索不到相关信息，请换个关键词试试
      </div>
    </div>
    <div v-show="mode == 3" class="result">
      <div class="list">
        <div class="series">
          <div class="introduction">
            <div class="r">
              <div class="name ellips">
                {{ seriesMore.titleBefore }}
                <span class="word">{{ keyword }}</span>
                {{ seriesMore.titleAfter }}
              </div>
              <div class="date">
                <span class="time">发布时间：{{ seriesMore.releaseTime }}</span>
                <span class="total">视频数：{{ list.length }}</span>
              </div>
              <div class="desc" v-text="seriesMore.description" />
            </div>
          </div>
          <ul>
            <li
              v-for="(video, videoInx) in seriesMore.categorySubVideoList"
              :key="videoInx"
              @click="play(video, 0)"
            >
              <span v-text="video.number" />
              <span v-if="video.roleStatus == 2" class="vip">
                VIP
              </span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import h from "@/components/base/header";
import { getHotWordList, getInputKeywordList, searchResult } from "@/api/video";
const storage = require("good-storage").default;
const SEARCH_KEY = "__search__";
const SEARCH_MAX_LEN = 5;

function insertArray(arr, val, compare, maxLen) {
  const index = arr.findIndex(compare);
  if (index === 0) {
    return;
  }
  if (index > 0) {
    arr.splice(index, 1);
  }
  arr.unshift(val);
  if (maxLen && arr.length > maxLen) {
    arr.pop();
  }
}

function deleteFromArray(arr, compare) {
  const index = arr.findIndex(compare);
  if (index > -1) {
    arr.splice(index, 1);
  }
}
export default {
  components: {
    h
  },
  data() {
    return {
      mode: 0,
      keyword: "",
      hotWrodList: [],
      searchHistory: [],
      types: ["全部", "免费", "会员", "金币"],
      roleStatus: 0,
      pageNumber: 1,
      pageSize: 20,
      total: 1,
      list: [],
      listLess: false,
      options: {
        click: true,
        pullDownRefresh: {
          threshold: 60,
          // stop: 44,
          stopTime: 1000,
          txt: "更新成功"
        },
        pullUpLoad: {
          threshold: 0,
          stopTime: 1000,
          txt: "数据加载成功"
        }
      },
      keywordSearchList: [],
      seriesMore: {}
    };
  },
  created() {
    this.getHistoryList();
    this.getHotWord();
  },
  methods: {
    keywordSearch() {
      if (this.keyword.trim() == "") {
        this.keywordSearchList = [];
        this.changeMode(0);
        return;
      }
      if (this.inputKeywordClick) {
        return;
      }
      this.changeMode(1);
      const params = {
        keyword: this.keyword
      };
      getInputKeywordList(params).then(res => {
        if (!res) {
          return;
        }
        this.keywordSearchList = res.object;
      });
    },
    historyKeywordSearch(item) {
      this.inputKeywordClick = true;
      this.keyword = item;
      this.search();
    },
    inputSearch(item) {
      this.inputKeywordClick = true;
      this.keyword = item.name;
      this.search();
    },
    presssearch() {
      this.$refs.searchInput.blur();
      this.search();
    },
    search() {
      if (this.keyword.trim() == "") {
        return;
      }
      this.changeMode(2);
      const params = {
        keyword: this.keyword,
        roleStatus: this.roleStatus,
        clientType: 3,
        pageNumber: this.pageNumber,
        pageSize: this.pageSize
      };
      this.saveSearch(this.keyword);
      searchResult(params)
        .then(res => {
          if (!res) {
            return;
          }
          if (this.pageNumber > 1) {
            this.list = this.list.concat(res.object.videoList);
          } else {
            this.list = res.object.videoList;
          }
          if (this.list.length <= 0) {
            this.listLess = true;
            return;
          }
          this.listLess = false;
          this.list.forEach(element => {
            element.titleBefore = element.name.split(this.keyword)[0];
            element.titleAfter = element.name.split(this.keyword)[1];
            if (element.categorySubVideoList.length > 0) {
              const newList = [];
              element.categorySubVideoList.forEach(item => {
                newList[item.number - 1] = item;
              });
              for (let i = 0; i < newList.length; i++) {
                if (newList[i] == undefined) {
                  newList[i] = {
                    number: i + 1,
                    url: "",
                    name: ""
                  };
                }
              }
              element.categorySubVideoList = newList;
              const allVip = element.categorySubVideoList.findIndex(vipItem => {
                return vipItem.roleStatus !== 2;
              });
              element.isAllVip = allVip;
            }
          });
          this.totalPages = res.object.total;
        })
        .finally(() => {
          this.inputKeywordClick = false;
        });
    },
    changeRole(inx) {
      this.roleStatus = inx;
      this.search();
    },
    onPullingDown() {
      this.pageNumber = 1;
      this.search();
    },
    onPullingUp() {
      if (this.pageNumber >= Math.ceil(this.total / this.pageSize)) {
        this.$refs.scroll.forceUpdate();
        return;
      }
      this.pageNumber++;
      this.search();
    },
    getHotWord() {
      getHotWordList().then(res => {
        if (!res) {
          return;
        }
        this.hotWrodList = res.object;
      });
    },
    getHistoryList() {
      this.saveSearch = query => {
        const searches = storage.get(SEARCH_KEY, []);
        insertArray(
          searches,
          query,
          item => {
            return item === query;
          },
          SEARCH_MAX_LEN
        );
        storage.set(SEARCH_KEY, searches);
        return searches;
      };
      this.deleteSearch = query => {
        const searches = storage.get(SEARCH_KEY, []);
        deleteFromArray(searches, item => {
          return item === query;
        });
        storage.set(SEARCH_KEY, searches);
        return searches;
      };
      this.clearSearch = () => {
        storage.remove(SEARCH_KEY);
        return [];
      };
      this.loadSearch = () => {
        return storage.get(SEARCH_KEY, []);
      };
      this.searchHistory = this.loadSearch();
    },
    saveSearchHistory(query) {
      this.searchHistory = this.saveSearch(query);
    },
    deleteSearchHistory(query) {
      this.searchHistory = this.deleteSearch(query);
    },
    clearSearchHistory() {
      this.searchHistory = this.clearSearch();
    },
    changeMode(mode) {
      this.mode = mode;
      if (this.mode == 0) {
        this.getHistoryList();
      }
    },
    play(item, inx, series = {}) {
      if (inx >= 9) {
        this.seriesMore = series;
        this.changeMode(3);
        return;
      }
      let url = "";
      if (item.videoType == 0 || item.videoType == 3) {
        url = `/video/${item.videoId}?type=${item.videoType}`;
      } else if (item.videoType == 1) {
        url = `/video/${item.categoryId}?type=${item.videoType}&number=${item.videoId}`;
      } else if (item.videoType == 2) {
        url = `/video/${item.categoryId}?type=${item.videoType}`;
      }
      this.$router.push(url);
    },
    resetUrl(url) {
      if (!url) {
        return;
      }
      return url.replace("http://qiniu", "https://qiniu");
    }
  }
};
</script>
<style lang="stylus" scoped>
@import "~assets/stylus/mixin.styl";
.search-container
  position fixed
  top 0
  right 0
  bottom 0
  left 0
  background #fff
  z-index 11
  .history
    position absolute
    top 36px
    right 0
    bottom 0
    left 0
    padding 20px 14px
    .title
      position relative
      margin-bottom 18px
      .name
        font-size 15px
      .btn
        position absolute
        right 0
        font-size 14px
        color #CECECE
    .list
      margin 0 5px 35px
      .item
        display inline-block
        padding 8px 15px
        margin 0 10px 12px 0
        background #F0F0F0
        border-radius 4px
        font-size 13px
        color #6B6B6B
  .keyword-list
    position absolute
    top 36px
    right 0
    bottom 0
    left 0
    padding 0 14px
    background #F2F5F6
    ul
      li
        padding 10px
        font-size 14px
  .not-data
    margin-top 64px
    text-align center
    font-size 14px
    color #6B6B6B
  .result
    position absolute
    top 36px
    right 0
    bottom 0
    left 0
    padding 20px 14px
    background #F2F5F6
    .top
      height 70px
      overflow hidden
      .title
        margin-bottom 16px
        font-size 14px
        span
          color #1E88E4
      .types
        .item
          padding 4px 19px
          margin-right 7px
          background #F2F5F6
          border 1px solid #E4E2E2
          border-radius 15px
          font-size 13px
          color #6B6B6B
          &.active
            background #1E88E4
            color #fff
            border-color #1E88E4
    .list
      height calc(100vh - 90px)
      overflow hidden
      .series
        margin-top 20px
        .introduction
          display flex
          margin-bottom 20px
          .l
            position relative
            flex 0 0 104px
            min-height 131px
            margin-right 8px
            .cover
              border-radius 4px
            .vip
              position absolute
              top 2px
              right 2px
              width 28px
              height 14px
              line-height 14px
              background #BA9877
              border-radius 4px
              font-style normal
              font-size 10px
              color #fff
              text-align center
          .r
            position relative
            flex 1
            overflow hidden
            .name
              margin-bottom 7px
              font-size 17px
              font-weight bold
              .word
                color #1e88e4
            .date
              margin-bottom 17px
              font-size 11px
              color #1E88E4
              .time
                margin-right 10px
            .desc
              font-size 11px
              color #6B6B6B
            .btn
              position absolute
              left 0
              bottom 5px
              width 100px
              height 30px
              line-height 30px
              padding 0
              border-radius 25px
              background linear-gradient(to right, #1A62DB, #2A9BFB)
              font-size 14px
              color #fff
        ul
          display flex
          flex-wrap wrap
          font-size 0
          li
            position relative
            flex 0 0 46px
            height 46px
            line-height 46px
            margin-right 26px
            margin-bottom 13px
            background #fff
            border-radius 8px
            text-align center
            font-size 17px
            color #6B6B6B
            &:nth-of-type(5n+0)
              margin-right 0
            .vip
              position absolute
              top 0
              right 0
              width 28px
              height 14px
              line-height 14px
              background #BA9877
              border-radius 4px
              font-style normal
              font-size 10px
              color #fff
              text-align center
              transform scale(.6)
</style>
